import { useState, useRef, useCallback } from 'react';

export type TransientState = {
  /**
   * Whether the transient state is currently active.
   */
  isActive: boolean;
  /**
   * Triggers the transient state to become active. If the transient
   * state is already active, it will be reset.
   *
   * @returns void
   */
  trigger: (duration: number) => void;
};

/**
 * On trigger, returns a transient state that will be active for the given
 * duration, in milliseconds.
 */
export function useTransientState(): TransientState {
  const [isActive, setIsActive] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const trigger = useCallback((duration: number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsActive(true);

    timeoutRef.current = window.setTimeout(() => {
      setIsActive(false);
      timeoutRef.current = null;
    }, duration);
  }, []);

  return { isActive, trigger };
}
