import { CptCode, Medication } from 'EntityTypes';
import {
  AppQueryConfig,
  MedicationSearchOptions,
  MedicationSearchResponse,
  PatientSearchOptions,
  ContactSearchOptions,
  PharmacySearchOptions,
  InsurancePlanSearchOptions,
  SpecialtySearchResult,
  SearchMedicalSpecialtiesOptions,
  SearchCPTCodesOptions,
  SearchAllergiesResponseBody,
  SnomedSearchResponseBody,
  type ContactSearchOptionsNew,
  type ContactSearchResult,
  InsuranceCompanySearchResult,
  SearchInsuranceCompaniesOptions,
} from 'QueryTypes';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { MedicationEntitiesState } from 'StoreTypes';
import { mapArrayToObject } from 'utils/arrays';
import { updateUrlParameter } from 'utils/http';
import USState from 'constants/USState';
import { ResponseError } from 'utils/errors';
import { DEFAULT_QUERY_RETRY_COUNT } from 'utils/react-query/AppQueryClient';

type ImoSearchFallbackMode = 'icd10' | 'icd9';

/**
 * Add search query params to the specified URL.
 */
function addUrlParams(
  url: string,
  query: string,
  limit: number,
  extraParams?: { [key: string]: string | number },
): string {
  let updatedUrl = url;
  updatedUrl = updateUrlParameter(updatedUrl, 'q', encodeURIComponent(query));
  updatedUrl = updateUrlParameter(updatedUrl, 'limit', limit);
  if (extraParams) {
    Object.keys(extraParams).forEach((paramKey): void => {
      const paramValue = extraParams[paramKey];
      if (paramValue !== undefined) {
        updatedUrl = updateUrlParameter(
          updatedUrl,
          encodeURIComponent(paramKey),
          encodeURIComponent(paramValue),
        );
      }
    });
  }
  return updatedUrl;
}

const urls = {
  allergies(query: string, limit = 75): string {
    return addUrlParams(`/s/search/allergies/`, query, limit);
  },
  contactsByName(
    practiceId: number,
    query: string,
    limit = 100,
    options: ContactSearchOptions = {},
  ): string {
    const { excludePractice = false, excludeUser = false, state } = options;
    const extraParams: {
      nolocal: 1 | 0;
      nouser: 1 | 0;
      state?: USState;
    } = {
      nolocal: excludePractice ? 1 : 0,
      nouser: excludeUser ? 1 : 0,
    };
    if (state) {
      extraParams.state = state;
    }

    return addUrlParams(`/practice/${practiceId}/connections/name/`, query, limit, extraParams);
  },
  cptCodes(query: string, limit = 25): string {
    return addUrlParams(`/cpt-codes/`, query, limit);
  },
  documentTags(query: string, limit = 100): string {
    return addUrlParams(`/practice/${el8Globals.PRACTICE_ID}/document-tags/`, query, limit);
  },
  imos(query: string, limit = 50, fallbackMode: ImoSearchFallbackMode = 'icd10'): string {
    return addUrlParams(`/imo-codes/search/`, query, limit, {
      fallback_mode: fallbackMode,
    });
  },
  insuranceCompanies(query: string, limit = 50): string {
    return addUrlParams('/insurance-companies/', query, limit);
  },
  insurancePlans(query: string, limit = 50, options?: InsurancePlanSearchOptions): string {
    const extraParams: { company_id?: number } = {};
    if (options?.companyId) {
      extraParams.company_id = options.companyId;
    }

    return addUrlParams('/insurance-plans/', query, limit, extraParams);
  },
  labOrderTests(query: string, limit = 100, compendiumId?: number): string {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    return addUrlParams('/lab-order-tests/', query, limit, { compendium_id: compendiumId });
  },
  /**
   * @param {boolean} [options.includeControlled = true] - whether or not to include
   * controlled meds in the search results.
   * @param {boolean} [options.includeTemplates = true] - whether or not to include
   * rx templates in the search results. Note that even if this is true, rx templates
   * will only be returned if the current user has the MedOrderTemplates feature
   * flag enabled.
   * @param {'otc'|'prescription'|'controlled'} [options.medType] - back end handling
   * of this is very wonky, not really sure about the API design here....but basically
   * (at time of writing this comment) this does nothing UNLESS you specify 'controlled',
   * in which case only controlled meds will be returned.
   */
  medications(
    query: string,
    limit = 200,
    {
      includeControlled = true,
      includeTemplates = true,
      medType,
      patientId,
    }: MedicationSearchOptions = {},
  ): string {
    const extraParams: {
      med_type?: MedicationSearchOptions['medType'];
      med_order_templates?: 0 | 1;
      allow_controlled?: 0 | 1;
      patient_id?: MedicationSearchOptions['patientId'];
    } = {};

    if (medType) {
      extraParams.med_type = medType;
    }
    if (el8Globals.FEATURES.MedOrderTemplates) {
      extraParams.med_order_templates = includeTemplates ? 1 : 0;
    }
    extraParams.allow_controlled = includeControlled ? 1 : 0;
    if (patientId) {
      extraParams.patient_id = patientId;
    }

    return addUrlParams('/medications/', query, limit, extraParams);
  },
  medicationBrands(query: string, limit = 75): string {
    return addUrlParams(`/s/search/medication-brands/`, query, limit);
  },
  patients(
    query: string,
    limit = 50,
    { enterpriseAware, globalSearchAware, suppressId }: PatientSearchOptions = {},
  ): string {
    const extraParams: { [param: string]: string | number } = {};
    if (globalSearchAware) {
      extraParams.global = '1';
    } else if (enterpriseAware) {
      extraParams.ent = '1';
    } else if (suppressId != null) {
      extraParams.suppress_id = suppressId;
    }
    return addUrlParams(`/s/search/patient-name/`, query, limit, extraParams);
  },
  patientTags(query: string, limit = 100): string {
    return addUrlParams(`/practice/${el8Globals.PRACTICE_ID}/patient-tags/`, query, limit);
  },
  pharmacies(query: string, limit = 100, options: PharmacySearchOptions = {}): string {
    const { patientId, rxType = 'newrx' } = options;

    let url = `/s/search/pharmacies/${rxType}/`;
    if (patientId) {
      url += `patient/${patientId}/`;
    }

    return addUrlParams(url, query, limit);
  },
  vaccines(query: string, limit = 100): string {
    return addUrlParams(`/practice/${el8Globals.PRACTICE_ID}/vaccines/`, query, limit);
  },
};

const queryKeys = {
  cptCodes: (options: SearchCPTCodesOptions): QueryKey => ['cpt-codes', { limit: 25, ...options }],
  /**
   * The base query query for the allergy search endpoint.
   */
  searchAllergies: (params: { q?: string; limit: number }): QueryKey => [
    's',
    'search',
    'allergies',
    params,
  ],
  searchInsuranceCompanies: (options: SearchInsuranceCompaniesOptions): QueryKey => [
    'insurance-companies',
    { limit: 50, ...options },
  ],
  /**
   * The base query query for the snomed search endpoint.
   */
  searchSnomed: (params: { q?: string; limit: number }): QueryKey => ['snomed', params],
  specialties: (practiceId: MaybeNullishId, options: SearchMedicalSpecialtiesOptions): QueryKey => [
    'practice',
    practiceId,
    'medical-specialties',
    options,
  ],
  searchInboxSenders: (practice: number, inboxId: number, q: string): QueryKey => [
    'practice',
    practice,
    'unfiled-documents',
    'feed-index',
    inboxId,
    'from-numbers',
    { q },
  ],
};

export const searchAllergiesQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.allergies(query, limit),
  update: {},
  force: true,
});

export const searchContactsByNameQuery = (
  practiceId: number,
  query: string,
  limit?: number,
  options?: ContactSearchOptions,
): AppQueryConfig => ({
  url: urls.contactsByName(practiceId, query, limit, options),
  update: {},
  force: true,
});

/**
 * Returns a list of all practice contacts by querying their name.
 *
 * **A bug is causing the server to return a 500 error when it has no search results.**
 * Until this bug is fixed:
 * 1. You should treat all 500 errors as "no results".
 * 2. This hook will skip Tanstack's retry logic for 500 errors.
 */
export function useSearchContactsByName(
  practiceId: MaybeNullishId,
  queryParams: ContactSearchOptionsNew,
  queryOptions: UseQueryOptions<ContactSearchResult[], ResponseError> = {},
): UseQueryResult<ContactSearchResult[], ResponseError> {
  const { query, limit = 100, ...restQueryParams } = queryParams;
  const { enabled: enabledOption, ...restQueryOptions } = queryOptions;

  const enabled = Boolean(practiceId && query) && enabledOption !== false;

  return useQuery({
    enabled,
    queryKey: [
      'practice',
      practiceId,
      'connections',
      'name',
      { q: query, limit, ...restQueryParams },
    ],
    retry: (failureCount, error) => {
      // A bug is causing the server to return a 500 error when it has no search results.
      // Until this bug is fixed, we want to treat all 500 errors as empty results and
      // abort Tanstack's retry logic.
      if (error?.status === 500) return false;
      return failureCount < DEFAULT_QUERY_RETRY_COUNT;
    },
    ...restQueryOptions,
  });
}

export const searchCptCodesQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.cptCodes(query, limit),
  update: {},
  force: true,
});

export const searchDocumentTagsQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.documentTags(query, limit),
  update: {},
  force: true,
});

export const searchImosQuery = (
  query: string,
  limit?: number,
  { fallbackMode }: { fallbackMode?: ImoSearchFallbackMode } = {},
): AppQueryConfig => ({
  url: urls.imos(query, limit, fallbackMode),
  update: {},
  force: true,
});

export const searchInsuranceCompaniesQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.insuranceCompanies(query, limit),
  force: true,
});

export const searchInsurancePlansQuery = (
  query: string,
  limit?: number,
  options?: InsurancePlanSearchOptions,
): AppQueryConfig => ({
  url: urls.insurancePlans(query, limit, options),
  force: true,
});

export const searchLabOrderTestsQuery = (
  query: string,
  limit?: number,
  compendiumId?: number,
): AppQueryConfig => ({
  url: urls.labOrderTests(query, limit, compendiumId),
  force: true,
});

// Quick and dirty cache of seen medication IDs. This is used to prevent excessive
// state updates while searching, because search queries have force: true and will
// frequently return medications that have already been seen before. We use this
// cache to send only medications we have NOT previously seen to the query updater
// function.
// NOTE: yes this cache is not aware of medications fetched via other methods,
// but currently searching is by far the most common way a medication is fetched.
const seenMedicationIds = new Set<number>();

export const searchMedicationsQuery = (
  query: string,
  limit?: number,
  options?: MedicationSearchOptions,
): AppQueryConfig => ({
  url: urls.medications(query, limit, options),
  transform: (responseJson: MedicationSearchResponse): { medications: Medication[] } => {
    const newMedications: Medication[] = [];
    responseJson.matches
      .filter((medication): boolean => !seenMedicationIds.has(medication.id))
      .forEach((medication): void => {
        newMedications.push(medication);
        seenMedicationIds.add(medication.id);
      });
    return {
      medications: newMedications,
    };
  },
  update: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(prevMedications: MedicationEntitiesState | ... Remove this comment to see the full error message
    medications: (
      prevMedications: MedicationEntitiesState = {},
      newMedications: Medication[],
    ): MedicationEntitiesState => {
      if (newMedications.length === 0) return prevMedications;

      return {
        ...prevMedications,
        byId: {
          ...prevMedications.byId,
          ...mapArrayToObject(newMedications, 'id'),
        },
      };
    },
  },
  force: true,
});

export const searchMedicationBrandsQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.medicationBrands(query, limit),
  update: {},
  force: true,
});

export const searchPatientsQuery = (
  query: string,
  limit?: number,
  options?: PatientSearchOptions,
): AppQueryConfig => ({
  url: urls.patients(query, limit, options),
  update: {},
  force: true,
});

export const searchPatientTagsQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.patientTags(query, limit),
  update: {},
  force: true,
});

export const searchPharmaciesQuery = (
  query: string,
  limit?: number,
  options?: PharmacySearchOptions,
): AppQueryConfig => ({
  url: urls.pharmacies(query, limit, options),
  force: true,
});

export const searchVaccinesQuery = (query: string, limit?: number): AppQueryConfig => ({
  url: urls.vaccines(query, limit),
  update: {},
  force: true,
});

export const useSearchCptCodes = (
  options: SearchCPTCodesOptions,
  queryOptions?: UseQueryOptions<CptCode[]>,
): UseQueryResult<CptCode[]> => {
  return useQuery({
    queryKey: queryKeys.cptCodes(options),
    ...queryOptions,
  });
};

export function useSearchMedicalSpecialties(
  practiceId: number | null,
  options: SearchMedicalSpecialtiesOptions,
): UseQueryResult<SpecialtySearchResult[]> {
  return useQuery({
    enabled: Boolean(options.q && practiceId),
    queryKey: queryKeys.specialties(practiceId, options),
  });
}

/**
 * Searchs for a list of allergies based on a query string.
 */
export function useSearchAllergies(
  query?: string,
  limit = 75,
  options?: Partial<UseQueryOptions<SearchAllergiesResponseBody>>,
): UseQueryResult<SearchAllergiesResponseBody> {
  const queryKey = queryKeys.searchAllergies({ q: query, limit });

  return useQuery({
    queryKey,
    enabled: query ? query.length > 1 : false,
    ...options,
  });
}

/**
 * Searchs for a list of snomed codes.
 */
export function useSearchSnomed(
  query?: string,
  limit = 50,
): UseQueryResult<SnomedSearchResponseBody> {
  const queryKey = queryKeys.searchSnomed({ q: query, limit });

  return useQuery({
    queryKey,
    enabled: query ? query.length > 1 : false,
  });
}

export const MIN_FAX_INBOX_SENDER_SEARCH_LENGTH = 3;

/**
 * Searchs for senders in a given fax inbox
 */
export const useSearchInboxSenders = (
  practiceId: number,
  inboxId: number, // This does not support the 'manual' inbox because there are no senders for that inbox
  query: string, // a 3 char minimum is used on the inbox sender search
  disabled: boolean = false,
): UseQueryResult<Record<string, number>> => {
  const queryKey = queryKeys.searchInboxSenders(practiceId, inboxId, query);
  return useQuery({
    queryKey,
    enabled: query && query.length >= MIN_FAX_INBOX_SENDER_SEARCH_LENGTH ? !disabled : false,
  });
};

export const useSearchInsuranceCompanies = (
  options: SearchInsuranceCompaniesOptions,
  queryOptions?: UseQueryOptions<InsuranceCompanySearchResult[]>,
): UseQueryResult<InsuranceCompanySearchResult[]> => {
  return useQuery({
    queryKey: queryKeys.searchInsuranceCompanies(options),
    ...queryOptions,
  });
};
