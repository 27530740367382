import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const API_AXIOS_INSTANCE = axios.create({
  headers: {
    'X-EL8-API-CLIENT': 'morval',
  },
});

/**
 * Axios wrapping request function used by all generated clients.
 * In order to handle authentication and CSRF headers the `API_AXIOS_INSTANCE`
 * should have an interceptor registered within the applications its used by.
 * @param config
 * @param options
 * @returns
 */
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = axios.CancelToken.source();
  const promise = API_AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

export { AxiosError };
