export default class ResponseError extends Error {
  constructor(response, ...args) {
    super(...args);

    this.body = response.body;
    this.status = response.status;
    this.text = response.text;
    this.headers = response.headers;
  }
}
