import ResponseError from './ResponseError';

type PydanticValidationError = {
  /** Type of validation error (e.g., "missing", "type_error") */
  type: string;

  /** Location of the error as an array of strings/numbers */
  loc: Array<string | number>;

  /** Human-readable error message */
  msg: string;

  /** Additional input-related information */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input?: Record<string, any>;
};

type PydanticValidationResponseError = ResponseError & {
  body: {
    errors: Array<{
      /** High-level error type (e.g., "other") */
      error: string;

      /** Array of detailed validation error messages */
      message: PydanticValidationError[];
    }>;
  };
};

/**
 * Helper to extract a user-friendly error message from a PydanticValidationResponseError
 */
export function getPydanticValidationResponseErrorText(err: ResponseError): string {
  // FUTURE: Improve error message formatting/transform for Pydantic errors
  // instead of raw JSON, e.g. {"errors":[{"error":"other","message":[{"type":"missing","loc": ...
  if (err.text) {
    return `Unexpected error(s): ${err.text}`;
  }
  return `Unknown error: ${err}`;
}

/**
 * Type guard to check if response error is (possibly) a Pydantic validation error
 * e.g. When request data fails FastAPI/Pydantic model validation and returns a 422 response.
 */
export function isPydanticValidationResponseError(
  err: unknown,
): err is PydanticValidationResponseError {
  if (err instanceof ResponseError && err.status === 422) {
    return err.body?.errors?.length > 0;
  }
  return false;
}
