import { CaseIntakeStep } from 'modules/support/supportConstants';
import CaseIntakeInitialForm from './steps/CaseIntakeInitialForm';
import CaseIntakeFormContact from './steps/CaseIntakeFormContact';
import CaseIntakeAIAnswerResolved from './steps/CaseIntakeAIAnswerResolved';
import CaseIntakeFormConfirmation from './steps/CaseIntakeFormConfirmation';
import CaseIntakeAIAnswer from './steps/CaseIntakeAIAnswer';
import CaseIntakeDoctorRedirect from './steps/CaseIntakeDoctorRedirect';
import CaseIntakeCaseForm from './steps/CaseIntakeCaseForm';
import { StepConfigObj } from './CaseIntakeTypes';

export const StepConfig: Record<CaseIntakeStep, StepConfigObj> = {
  [CaseIntakeStep.answered]: {
    step: CaseIntakeStep.answered,
    component: CaseIntakeAIAnswer,
    title: "Here's some information from Elation's Help Center.",
  },
  [CaseIntakeStep.direct_to_physician]: {
    step: CaseIntakeStep.direct_to_physician,
    component: CaseIntakeDoctorRedirect,
    title: 'Need to Contact Your Doctor?',
  },
  [CaseIntakeStep.create_case]: {
    step: CaseIntakeStep.create_case,
    component: CaseIntakeCaseForm,
    title: "Let's get a support ticket started",
  },
  [CaseIntakeStep.Intake]: {
    step: CaseIntakeStep.Intake,
    component: CaseIntakeInitialForm,
    title: 'How can we help you?',
  },
  [CaseIntakeStep.ContactInfo]: {
    step: CaseIntakeStep.ContactInfo,
    component: CaseIntakeFormContact,
    title: 'Finally, let us know how to reach you.',
  },
  [CaseIntakeStep.CaseSubmitted]: {
    step: CaseIntakeStep.CaseSubmitted,
    component: CaseIntakeFormConfirmation,
    title: "Your ticket has been created and sent to Elation's Support team.",
  },
  [CaseIntakeStep.IssueResolved]: {
    step: CaseIntakeStep.IssueResolved,
    component: CaseIntakeAIAnswerResolved,
    title: 'Your issue has been resolved!',
  },
};
