import React from 'react';
import { Dialog, DialogProps } from '@el8/vital';
import cn from 'classnames';

import { incrementAndGetMaxZ } from 'utils/overlays';
import styles from './LegacyFriendlyDialog.less';

export type LegacyFriendlyDialogProps = Omit<DialogProps, 'isModal' | 'getMaxZ'> & {
  isOpen: boolean;
};

/**
 * An extension of Vital's Dialog component that has functionality baked into it
 * that will allow it to coexist with any legacy jQuery UI dialogs on the same page.
 * In other words, this means you can click a LegacyFriendlyDialog and bring it
 * in front of legacy dialogs, and also click on legacy dialogs to bring it in
 * front of LegacyFriendlyDialogs.
 *
 * Generally, for any page that has a mix of React dialogs and legacy dialogs,
 * all the non-modal dialogs should be LegacyFriendlyDialogs as opposed to plain
 * Dialogs.
 */
export default class LegacyFriendlyDialog extends React.Component<LegacyFriendlyDialogProps> {
  render(): JSX.Element {
    const { bodyClassName, children, ...props } = this.props;

    return (
      <Dialog
        bodyClassName={cn(bodyClassName, styles.dialogBody)}
        {...props}
        isModal={false}
        getMaxZ={incrementAndGetMaxZ}
      >
        {children}
      </Dialog>
    );
  }
}
