import React from 'react';

import { Group, Stack, Text2 } from '@el8/vital';

import { CaseIntakeStep } from 'modules/support/supportConstants';

import { useFormikContext } from 'formik';
import { NextStep } from '@elation/api-client/src/support';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './CaseIntakeDoctorRedirect.less';
import { logIntakeAIAnswerResolved, logIntakeCreateTicket } from '../amplitudeTaxonomy';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import { IntakeButton } from '../components/IntakeButton';

const CaseIntakeDoctorRedirect = ({
  goToNextStep,
  isInModal,
}: CaseIntakeStepProps): JSX.Element => {
  const { values } = useFormikContext<CaseIntakeFormValues>();
  return (
    <Stack data-testid="support-doctor-redirect-container" gap="md" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.direct_to_physician} />
      <Stack gap="xl">
        <div>
          <Text2 sizing="lg">
            Please reach out to your doctor directly by{' '}
            <Text2
              data-testid="support-doctor-redirect-patient-passport-link-1"
              href="https://www.elationhealth.com/passport-loading/"
              rel="noopener noreferrer"
              tag="a"
              target="_blank"
              sizing="lg"
            >
              logging in here
            </Text2>{' '}
            if your inquiry relates to any of the following:
          </Text2>
          <ul className={styles.list}>
            <Text2 tag="li" sizing="lg">
              Updating demographics (e.g., address, phone number, etc.)
            </Text2>
            <Text2 tag="li" sizing="lg">
              Accessing or updating medical records or lab results
            </Text2>
            <Text2 tag="li" sizing="lg">
              Billing inquiries (e.g., payments, refunds, subscriptions, or memberships)
            </Text2>
            <Text2 tag="li" sizing="lg">
              Appointment cancellations
            </Text2>
            <Text2 tag="li" sizing="lg">
              Medication refills, changes, or questions
            </Text2>
          </ul>
        </div>

        <div>
          <Text2 sizing="lg">You can contact your doctor in one of the following ways:</Text2>
          <ul className={styles.list}>
            <Text2 tag="li" sizing="lg">
              Call their practice directly at the listed phone number.
            </Text2>
            <Text2 tag="li" sizing="lg">
              Send a secure message via Patient Passport by{' '}
              <Text2
                data-testid="support-doctor-redirect-patient-passport-link-2"
                href="https://www.elationhealth.com/passport-loading/"
                rel="noopener noreferrer"
                tag="a"
                target="_blank"
                sizing="lg"
              >
                logging in here
              </Text2>
              .
            </Text2>
          </ul>
        </div>
        <Text2 sizing="lg">
          For concerns outside these topics, review our FAQs. If you still have questions, contact{' '}
          <Text2
            data-testid="support-doctor-redirect-patient-passport-link"
            href="https://help.elationhealth.com/s/article/Patient-Passport-Frequently-Asked-Questions-for-Patients"
            rel="noopener noreferrer"
            tag="a"
            target="_blank"
            sizing="lg"
          >
            Elation Health Support
          </Text2>
          .
        </Text2>
      </Stack>
      <hr />
      <div>
        <Stack gap="xs">
          <Text2 sizing="lg">Did we answer your question?</Text2>
          <Group>
            <IntakeButton
              data-testid="support-doctor-resolved-button"
              className={styles.btn}
              label="Yes"
              onClick={(): void => {
                logIntakeAIAnswerResolved(values, NextStep.answered);
                goToNextStep(CaseIntakeStep.IssueResolved);
              }}
            />
            <IntakeButton
              data-testid="support-doctor-redirect-continue-button"
              className={styles.btn}
              label="No, I still need help"
              onClick={(): void => {
                logIntakeCreateTicket(values, NextStep.direct_to_physician);
                goToNextStep(CaseIntakeStep.create_case);
              }}
            />
          </Group>
        </Stack>
      </div>
    </Stack>
  );
};

export default CaseIntakeDoctorRedirect;
