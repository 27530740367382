import { useCallback, useMemo, useState } from 'react';

import { ListAPIPaginationInfo } from 'QueryTypes';
import { PaginatorProps } from '@el8/vital';

type ListAPIPaginationInfoWithoutUrls = Omit<
  ListAPIPaginationInfo,
  'next_page_url' | 'previous_page_url'
>;

type UsePaginationOptions = {
  initialOffset?: number;
  initialPaginationData?: ListAPIPaginationInfoWithoutUrls;
  limit?: number;
};

type UsePaginationResult = {
  limit: number;
  offset: number;
  setPaginationData: (paginationData: ListAPIPaginationInfoWithoutUrls) => void;
  firstPage: () => void;
  nextPage: () => void;
  prevPage: () => void;
  lastPage: () => void;
  setPage: (page: number) => void;
  paginatorProps: PaginatorProps;
};

/**
 * A hook that manages pagination, especially tailored for use with our paginated APIs.
 *
 * Sample usage:
 * ```
 * const { limit, offset, nextPage, prevPage, setPaginatedData } = usePagination({ limit: 30, initialOffset: 0 });
 * const { data } = useGetSomethingQuery(
 *   // pass `limit` and `offset` from usePagination's return value into the query
 *   { limit, offset },
 *   {
 *     // onSuccess is nested under the `query` key with Morval hooks. When directly calling
 *     // `useQuery`, onSuccess would be at the top level of this object instead.
 *     query: {
 *       onSuccess(data) {
 *         // lets usePagination know about the server response so it can correctly
 *         // manage pagination, including handlers for nextPage, prevPage, etc.
 *         setPaginatedData(data);
 *       },
 *     },
 *   },
 * });
 * ```
 */
export default function usePagination({
  initialOffset = 0,
  initialPaginationData,
  limit = 20,
}: UsePaginationOptions = {}): UsePaginationResult {
  const [offset, setOffset] = useState(initialOffset);
  const [paginationData, setPaginationData] = useState(initialPaginationData);

  const setPage = useCallback(
    (page: number): void => {
      if (page < 1) {
        throw new Error(`Failed to set page to ${page}: page number must be >= 1`);
      }
      if (!Number.isInteger(page)) {
        throw new Error(`Failed to set page to ${page}: page number must be an integer`);
      }
      if (paginationData && page > paginationData.total_page_count) {
        throw new Error(
          `Failed to set page to ${page}: out of bounds (${paginationData.total_page_count} total pages)`,
        );
      }

      // page numbers are 1-indexed in our APIs for user-readability,
      // so subtract 1 to treat it as 0-indexed for offset calculation
      setOffset(limit * (page - 1));
    },
    [limit, paginationData],
  );

  const firstPage = useCallback((): void => {
    setPage(1);
  }, [setPage]);

  const nextPage = useCallback((): void => {
    if (paginationData) {
      const nextPageStartOffset = offset + limit;
      const finalItemOffset = paginationData.total_item_count - 1;
      const hasNextPage = nextPageStartOffset <= finalItemOffset;
      if (hasNextPage) {
        setOffset(nextPageStartOffset);
      }
    }
  }, [limit, offset, paginationData]);

  const prevPage = useCallback((): void => {
    if (paginationData) {
      const prevPageOffset = offset - limit;
      setOffset(Math.max(prevPageOffset, 0));
    }
  }, [limit, offset, paginationData]);

  const lastPage = useCallback((): void => {
    if (paginationData && paginationData.current_page_num !== paginationData.total_page_count) {
      setPage(paginationData.total_page_count);
    }
  }, [paginationData, setPage]);

  const paginatorProps = useMemo<PaginatorProps>(() => {
    return {
      first: paginationData?.page_first_item_num,
      last: paginationData?.page_last_item_num,
      total: paginationData?.total_item_count,
      onFirst: firstPage,
      onLast: lastPage,
      onNext: nextPage,
      onPrev: prevPage,
    };
  }, [firstPage, lastPage, nextPage, paginationData, prevPage]);

  return {
    limit,
    offset,
    setPaginationData,
    firstPage,
    nextPage,
    prevPage,
    lastPage,
    setPage,
    paginatorProps,
  };
}
