export enum VisitNoteFormat {
  /** AKA Simple Note */
  OneColOneField = 'oneCol.oneField',
  OneColSimple = 'oneCol.simple',
  OneColPreOp = 'oneCol.preOp',
  OneColPreOp2 = 'oneCol.preOp2',
  TwoColComplex = 'twoCol.complex',
  TwoColComplex2 = 'twoCol.complex2',
  Note2 = 'note2',
}
