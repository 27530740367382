/**
 * Retrieves jQuery UI's internal `maxZ` variable, increments it, and returns the
 * updated value.
 *
 * The function ensures that new references to jQuery UI's z-index management
 * are assigned a value higher than any currently tracked non-modal elements,
 * bringing them to the top of the z-index stack.
 *
 * This relies on internal jQuery UI behavior and may break with jQuery version
 * upgrades.
 *
 * @returns number - The new highest z-index value.
 */
export function incrementAndGetMaxZ(): number {
  const maxZ = $.ui.dialog.maxZ ? $.ui.dialog.maxZ : 0;

  if (maxZ === 0) {
    $.ui.dialog.maxZ = 1000;
  } else {
    $.ui.dialog.maxZ++;
  }

  return $.ui.dialog.maxZ;
}
