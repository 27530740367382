/**
 * Represents an aggregation of PerformanceResourceTimings that are
 * specific to the javascript files in our bundles (mini.*.js)
 */
interface BundleSizeReport {
  /**
   * The total size of our JavaScript bundles (mini.*.js), after removing any
   * applied content encodings (like gzip).
   *
   * Does not include all of the javascript that might be on the page
   * (like third party platform sdk snippets).
   */
  totalDecodedBundleSize: number;
  /**
   * The total size of our JavaScript bundles (mini.*.js), before removing any
   * applied content encodings (like gzip).
   *
   * Does not include all of the javascript that might be on the page
   * (like third party platform sdk snippets).
   */
  totalEncodedBundleSize: number;
  /**
   * The total size of our JavaScript bundles (mini.*.js) that was transmitted
   * across the network. (Cached files add 0 size to this value).
   *
   * Does not include all of the javascript that might be on the page
   * (like third party platform sdk snippets).
   */
  totalTransmittedBundleSize: number;
  /**
   * The total size of our JavaScript bundles (mini.*.js) that was retrieved
   * from the browser cache.
   *
   * Does not include all of the javascript that might be on the page
   * (like third party platform sdk snippets).
   */
  totalCachedBundleSize: number;
}

function getBundleSizeReport(): BundleSizeReport {
  /**
   * All of the resources belonging to "OUR" bundle. Speficially that is:
   * - our webpack-built react chunks
   * - our closure-compiler-built legacy chunks
   *
   * DOES NOT include extra javascript that's loaded on the page through
   * the html files (like sentry, datadog, etc...)
   */
  const bundle = performance
    .getEntriesByType('resource')
    .filter((r) => r.name.match(/mini\..*\.js/)) as PerformanceResourceTiming[];

  return bundle.reduce(
    (acc, resource) => {
      const cachedSize = resource.transferSize === 0 ? resource.encodedBodySize : 0;
      return {
        totalDecodedBundleSize: acc.totalDecodedBundleSize + resource.decodedBodySize,
        totalEncodedBundleSize: acc.totalEncodedBundleSize + resource.encodedBodySize,
        totalTransmittedBundleSize: acc.totalTransmittedBundleSize + resource.transferSize,
        totalCachedBundleSize: acc.totalCachedBundleSize + cachedSize,
      };
    },
    {
      totalDecodedBundleSize: 0,
      totalEncodedBundleSize: 0,
      totalTransmittedBundleSize: 0,
      totalCachedBundleSize: 0,
    },
  );
}

window.addEventListener('load', () => {
  const report = getBundleSizeReport();
  window.DD_RUM?.addAction('BundleSizeReport', report);
});
