import { QueryClient } from '@tanstack/react-query';

import { getQueryUrl } from './queryKeys';
import { jsonFetch } from './requests';

export const DEFAULT_QUERY_RETRY_COUNT = 3;

/**
 * A `QueryClient` that comes with custom defaults for our app.
 *
 * In particular, a default `queryFn` is provided and query keys should
 * follow a certain format in order for the default function to work.
 * The query key should contain the main URL segments split on `/`, followed by
 * an optional query params object if applicable.
 *
 * For example, to use the URL `/patients/123/`, the query key should be:
 * ```
 * ['patients', 123]
 * ```
 * To use the URL `/patients/123/documents/456/?status=active&is_confidential=false`,
 * the query key should be:
 * ```
 * [
 *   'patients',
 *   123,
 *   'documents',
 *   456,
 *   { status: 'active', is_confidential: false }
 * ]
 * ```
 * This convention roughly adheres to react-query best practices, as far as I can tell:
 * - any parameter used in the URL should also be included in the query key so the
 * query will correctly refetch automatically whenever relevant parameters change
 * - using granular query key segments allows for fine-grained control of queries,
 * for things like invalidation and refetching
 */
const AppQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ meta, queryKey }): Promise<unknown> => {
        return jsonFetch(getQueryUrl(queryKey, meta));
      },
      refetchOnWindowFocus: false,
      retry: DEFAULT_QUERY_RETRY_COUNT,
    },
  },
});

export default AppQueryClient;
